import React from 'react'
import { Link } from 'gatsby'
import { DefaultLayout } from '../components/Layouts'

const linkClasses = 'underline text-grey-700 hover:text-grey-900'

const NotFoundPage = () => (
  <DefaultLayout>
    <div className="text-center text-grey-600 max-w-xl">
      <h1 className="font-bold text-4xl text-black mb-2">
        There's nothing here
      </h1>
      <p className="mb-2">
        ...but there could be! Contact us to create your brand's style guide.
      </p>
      <p>
        <Link to="/" className={linkClasses}>
          Return to the homepage
        </Link>{' '}
        or send a message our team at{' '}
        <a href="mailto:hello@northxsouth.co" className={linkClasses}>
          hello@northxsouth.co
        </a>{' '}
        for assistance.
      </p>
    </div>
  </DefaultLayout>
)

export default NotFoundPage
